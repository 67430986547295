@tailwind base;
@tailwind components;
@tailwind utilities;

@import './reset';

.primary-btn {
  @apply p-3 rounded-xl text-white flex justify-center items-center;
  border: 1px solid #F33B1D;
  background: linear-gradient(180deg, #FE7D4B 0%, #FB6722 51.46%, #EF3A1D 100%);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.25), 0px 1px 0.5px 0px rgba(254, 192, 81, 0.36) inset, 0px -0.5px 0.5px 0px #D21818 inset;
  transition: all 0.3s ease;

  &.outlined {
    border-color: #fff;
    background: transparent;

    &:hover {
      background: #fff;
      color: #101020;
    }
  }
}

body {
  background-color: #101020;
  font-family: 'PPFormula-Medium', sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  transform: translateY(2px);
}

section {
  @apply relative w-full py-16;
}

.slick-list {
  overflow: visible !important;
  -webkit-scrollbar: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.prediction {
  .grid-col {
    @apply p-8 rounded-3xl relative w-full;
    min-height: 230px;

    h3 {
      @apply text-2xl mb-1 text-white font-medium;
    }

    p {
      @apply text-offWhite;
    }
  }
}

.prediction ul {
  position: relative;
  z-index: 9;
  margin-top: 0.7rem;
  list-style-type: disc;
  padding-left: 16px;
  font-weight: 500;
}

@media(min-width:1600px) {
  .slick-track {
    margin: 0 !important;
  }
}

@font-face {
  font-family: 'PPFormula-Medium';
  src: url('../assets/fonts/FontsFree-Net-PPFormula-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PPFormula-Bold';
  src: url('../assets/fonts/FontsFree-Net-PPFormula-ExtendedBold-1.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}