.hero {
  @apply flex justify-center items-center relative;
  min-height: 600px;

  header {
    @apply text-center flex flex-col items-center;

    h1 {
      @apply text-3xl md:text-4xl text-white;
      line-height: 1.4;
    }

    p {
      @apply text-white mt-6 mb-12;
    }

    button.primary-btn {
      @apply px-5;
    }
  }

  .social-strip {
    @apply bottom-0 md:bottom-[100px];
    position: absolute;
    right: 0;
  }
}