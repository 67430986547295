.content {
    @apply text-white flex flex-col gap-10 relative;

    h4 {
        @apply text-2xl mb-3;
    }

    &::before {
        content: '';
        display: block;
        height: 100px;
        width: 2px;
        position: absolute;
        left: -20px;
        top: 0;
        background-color: #FD7740;
    }

    li:nth-child(2):hover:before {
        top: 100px;
    }
}