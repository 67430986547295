.how-it-works {
    @apply bg-[#FD7740] text-white rounded-xl;

    p {
        @apply mb-1;
    }

    h2 {
        @apply text-2xl mb-4;
        font-weight: bold;
    }

    h4 {
        font-weight: bold
    }

    .how-card {
        img {
            @apply mb-4;
        }

        h4 {
            @apply mb-4;
        }
    }
}