.faq {
    h2 {
        @apply text-3xl text-white mb-12;
        line-height: 1.8;
    }
}

.faq-card {
    @apply p-4 px-6 text-white;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.08);

    .title {
        @apply flex justify-between items-center;
    }

    p {
        @apply text-[#b4b4b4] mt-6;
    }
}